import ProductService from './ProductService';
import firebase from "firebase";
import 'firebase/storage';

export default class OrderServise {
    productService = new ProductService;
    order = {
        items: [
        // {
        //     productId: null,
        //     price: ,
        //     quantity: 1,
        // }
        ],
        customer: {
            name: '',
            phone: '',
            address: '',
        },
        total: {
            sum: '',
            weight: '',
            quantity: ''       
        }
    };



    products = [];

    async addProduct (p) {
        var found = false

        for (let item of this.order.items) {
            if (item.productId == p.id) {
                item.quantity += p.quantity;
                item.price += p.price;
                found = true;
                break;
            } 
        }
        if (!found) {
            this.order.items.push({productId: p.id, quantity: p.quantity, price: p.price});
        }
        
        await this.getProducts();
        this.calculateOrder ();
    }

    async getProducts() {
        var prods = [];

        for (let item of this.order.items) {
            prods.push(await this.productService.getProduct(item.productId))
            for (let p of prods) {
                if (item.productId == p.id) {
                    p.quantity = item.quantity
                }
            }
        }
        this.products = prods;
    }

    calculateOrder () {
        this.order.total.sum = 0;
        this.order.total.quantity = 0;
        this.order.total.weight = 0;
        for (let item of this.order.items) {
            this.order.total.sum += item.price;
            this.order.total.quantity += item.quantity;
            for (let p of this.products) {
                if (item.productId == p.id){
                this.order.total.weight += p.size*item.quantity;}
            }
        }
        
        console.log(this.order.items.length, this.order.total.sum)
    }


    deleteOrderItem (id) {
        for (let i = 0; i < this.order.items.length; i++) {
            if (this.order.items[i].productId == id) {
                this.order.items.splice(i, 1);
                break;
            } 
        }
        this.calculateOrder ();
        this.getProducts ();

    }

    increment (o) {
        for (let item of this.order.items) {
            if(item.productId == o.id) {
                item.quantity++;
                item.price += o.price
            }
        }

        this.calculateOrder ();
        this.getProducts ();

    }

    decrement (o) {
        for (let item of this.order.items) {
            if(item.productId == o.id && item.quantity > 1) {
                item.quantity--;
                item.price -= o.price
            }
        }

        this.calculateOrder ();
        this.getProducts ();
    }

    async saveOrder(o) {
		var ref;
        if (o.id) {
			ref = await firebase.firestore().collection("orders").doc(o.id).set(o);
		} else {
        ref = firebase.firestore().collection("orders").doc();
		o.id = ref.id;
		await ref.set(o);
        }
		
		return o.id;
	}
}